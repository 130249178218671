import aboutMeImg from "../images/selfy.png";
import { motion } from "framer-motion";
import SocialIcons from "../components/SocialIcons";
import { useInView } from "react-intersection-observer";
import { useState, useEffect } from "react";

const AboutMe = ({ name, email, location, availability, brand }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    setDownloading(false);
  }, [downloading]);

  // const handleDownload = () => {
  //   setDownloading(true);
  //   const link = document.createElement("a");
  //   link.href = resume;
  //   link.download = "Qritarea教育サービス概要.pdf";
  //   link.onload = () => {
  //     link.remove();
  //     setDownloading(false);
  //   };
  //   document.body.appendChild(link);
  //   link.click();
  // };

  return (
    <div className="aboutContainer container">
      <div className="row">
        <motion.div
          className="personalImage col-12 col-lg-4"
          ref={ref}
          initial={{ x: "-10vw", opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : { x: "-10vw", opacity: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <img src={aboutMeImg} alt={name} />
        </motion.div>
        <motion.div
          className="personalInfo col-12 col-lg-8"
          ref={ref}
          initial={{ x: "10vw", opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : { x: "10vw", opacity: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <div className="contentContainer">
            <h4>Qritarea CEO - Seiya Ohara</h4>
            <h5>経営・開発・投資の３軸で必要なコンサルティング・教育サービスをご提供</h5>
            <div className="contentDescription">
              <p>{brand}</p>
            </div>
            <div className="infoContainer">
              <div className="row">
                <div className="col-12 col-md-6 info">
                  <span>CompanyName:</span>
                  <p>Qritarea Inc.</p>
                </div>
                <div className="col-12 col-md-6 info">
                  <span>Email:</span>
                  <p>
                    <a href={`mailto:${email}`}>{email}</a>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 info">
                  <span>Location:</span>
                  <p>{location}</p>
                </div>
                <div className="col-12 col-md-6 info">
                  <span>Availability:</span>
                  <p>{availability}</p>
                </div>
              </div>
            </div>
            <div style={{fontSize:12, color:"silver", marginTop: 24, marginBottom: 24}}>
              {/* <p>顧問先の定員は5社で, 現在5社契約しています. 満枠のため, 応募いただいてもお引き受けできない状態です.</p> */}
              <p>顧問先の定員は5社で, 現在4社契約しています. 1枠空いている状態です.</p>
              <br/>
              <ul>現在の顧問先
                <li> - Recalmo合同会社</li>
                <li> - SynCHRO株式会社</li>
                <li> - 合同会社See-through Bounce</li>
                <li> - NPO法人FitnessEngineer</li>
              </ul>
              <br/>
              <ul>過去の顧問先
                <li> - caname株式会社（旧称 かたぎり塾）</li>
                <li> - 合同会社トレーナーシップ</li>
                <li> - 株式会社SE.RING.IT</li>
                <li> - 株式会社OWLS</li>
              </ul>
            </div>
            <div style={{float:"right", marginTop: 24, marginBottom: 24}}>
              <SocialIcons />
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutMe;
