import { motion } from "framer-motion";
import Typewriter from "typewriter-effect";
import landingImage from "../../images/b_qritarea.jpg";
// import Draw from "../../components/Draw";
import SocialIcons from "../../components/SocialIcons";

const Landing = ({ name }) => {
  const styles = {
    landing: {
      height: "calc(100% - 93px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    landingImage: {
      position: "absolute",
      bottom: "0",
      opacity: "0.3",
      mixBlendMode: "lighten",
      height: "80%",
    },

    textContainer: {
      display: "flex",
      flexDirection: "column",
      letterSpacing: "1px",
      textAlign: "center",
      zIndex: "1",
      color: "#fff",
      textShadow: "1px 1px 3px #000",
    },

    name: {
      color: "#fff",
      fontWeight: "700",
      marginTop: "-100px",
      paddingBottom: "28px",
    },
  };

  return (
    <section className="landing" style={styles.landing}>
      {/* <Draw /> */}
      <div className="textContainer" style={styles.textContainer}>
        <h1 className="name" style={styles.name}>
          {name}
        </h1>
        <div className="description">
          <Typewriter
            className="description"
            onInit={(typewriter) => {
              typewriter
                .typeString("Entrepreneurial Consulting.")
                .pauseFor(300)
                .deleteChars(27)
                .pauseFor(300)
                .typeString("System Development.")
                .pauseFor(300)
                .deleteChars(19)
                .pauseFor(300)
                .typeString("Web Direction.")
                .pauseFor(300)
                .deleteChars(14)
                .pauseFor(300)
                .typeString("Brand Design.")
                .pauseFor(300)
                .deleteChars(13)
                .pauseFor(300)
                .typeString("Customer Acquisition.")
                .pauseFor(300)
                .deleteChars(21)
                .pauseFor(300)
                .typeString("Investment Advisory.")
                .pauseFor(300)
                .deleteChars(20)
                .pauseFor(300)
                .typeString("Support for Use of AI Tools.")
                .pauseFor(300)
                .deleteAll()
                .typeString("Need help with business, development, or investment?")
                .start();
            }}
          />
        </div>
      </div>
      <div className="image-container">
        <motion.img
          className="landingImage"
          initial={{ opacity: 0 }}
          animate={{ opacity: 0.4 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          style={styles.landingImage}
          src={landingImage}
          alt="Qritarea"
        />
      </div>
      <SocialIcons />
    </section>
  );
};

export default Landing;
