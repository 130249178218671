import Header from "./components/Header";
import AnimatedRoutes from "./components/AnimatedRoutes";

function App() {
  const personalDetails = {
    name: "Qritarea",
    location: "1-38 Shindenkita-cho, Daito-shi, Osaka, JP",
    email: "ask@qritarea.com",
    availability: "On Vacation",
    brand:
      "京都大学理学部卒業, 東京大学大学院中退；独立した後2018年に合同会社Qritarea設立；現在, HRテックベンチャー企業の開発責任者として勤務する傍ら, パーソナルジムの経営と暗号通貨投資に従事 / B.S., Kyoto University; M.S., University of Tokyo; Currently working as a development manager for a HR tech venture company, Personal gym Owner and Cryptocurrency Investor.",
  };

  return (
    <>
      <Header />
      <AnimatedRoutes personalDetails={personalDetails} />
      {/* <footer>
        <span class="version">v1.0.0</span>
        <div class="centerContainer">
          <span class="copyright">© 2024 Qritarea</span>
        </div>
        <a href="https://line.me/ti/p/CpN8AkZ_Rs" class="consultation">こちらから無料で相談可.</a>
      </footer> */}
    </>
  );
}

export default App;
