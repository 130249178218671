
import PageHeader from "../../components/PageHeader";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useState, useEffect } from "react";
// import resume from "./Qritarea教育サービス概要.pdf";

const ServiceDetailModal = ({ isOpen, onClose, serviceDetail }) => {
  if (!isOpen) return null;

  return (
    <div className="modalOverlay" onClick={onClose}>
      <div className="modalContent">
        <h2>{serviceDetail.title}</h2>
        <br className="sp" />
        <div className="divider"></div>
        <p>{serviceDetail.description}</p>
        <p>{serviceDetail.detail}</p>
        <div className="divider"></div>
        <br className="sp" />
        <div className="buttonContainer">
          <button onClick={onClose}>申し込む</button>
          {/* <button onClick={onClose}>申し込む</button> */}
        </div>
      </div>
    </div>
  );
};

const Service = () => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const [downloading, setDownloading] = useState(false);

  useEffect(() => {
    setDownloading(false);
  }, [downloading]);

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const services = [
    {
      title: "各種適性診断",
      description: "起業, 開発, 投資の中で希望する適性診断と３０分の面談を受けられます.",
      detail: "基本的に起業塾、開発塾、投資塾のいずれかの受講を検討されている方向けになります。自分がビジネスやプログラミングに適性があるのか気になる方におすすめです。投資については安定運用の場合は誰でも大丈夫ですが、リスクを取り運用したい場合は適性が重要なので適性を確認しておくと良いでしょう。",
      price: "8,800円",
      ennabled: true
    },
    // {
    //   title: "定期メンター",
    //   description: "サポートが必要な期間, 隔週月２回で３０分間の成長支援を受けられます.",
    //   detail: "基本的に起業塾、開発塾、投資塾のいずれかを受講された方が対象となります。個人の目標達成や成長のために必要な課題をその時点のレベルに合わせて提供します。課題やアクションについては実行するたびに報告してもらい、面談時にフィードバックを行なっています。",
    //   price: "6,600円（月額）",
    //   ennabled: true
    // },
    {
      title: "各種顧問契約",
      description: "レベニューシェア等の条件を組み合わせて月額を調整することも可能です.",
      detail: "基本的に起業塾、開発塾、投資塾のいずれかを受講された方が対象となります。個人の目標達成や成長のために必要な課題をその時点のレベルに合わせて提供します。課題やアクションについては実行するたびに報告してもらい、面談時にフィードバックを行なっています。",
      price: "330,000円（月額）",
      ennabled: false
    },
    // {
    //   title: "独立支援合宿",
    //   description: "短期間で起業と開発のエッセンスを学び, 実践をスタートできる合宿です.",
    //   detail: "基本的に起業塾、開発塾の受講前にブーストをかけたい方向けのサービスです。オフィスにて座学と実技に取り組んでもらうため、近場に住んでいない方はホテルなどをとっていただく必要があります。週１で積み上げて学ぶよりガッツリ集中して短期で成長したい人におすすめです。１ヶ月の質問サポートがついています。",
    //   price: "132,000円（2泊3日）",
    //   ennabled: true
    // },
    // {
    //   title: "限定公開教材",
    //   description: "何度も見返す価値のある起業, 開発, 投資のノウハウにアクセスできます.",
    //   detail: "基本的に起業塾、開発塾、投資塾のいずれかを受講された方が対象となります。個人の目標達成や成長のために必要な課題をその時点のレベルに合わせて提供します。課題やアクションについては実行するたびに報告してもらい、面談時にフィードバックを行なっています。",
    //   price: "55,000円（買切）",
    //   ennabled: true
    // },
    // {
    //   title: "入塾費",
    //   description: "初月のみ必要な費用で, 塾の運営に必要なツールなどの費用に充てられます.",
    //   detail: "基本的に起業塾、開発塾、投資塾のいずれかを受講された方が対象となります。個人の目標達成や成長のために必要な課題をその時点のレベルに合わせて提供します。課題やアクションについては実行するたびに報告してもらい、面談時にフィードバックを行なっています。",
    //   price: "22,000円（初回）",
    //   ennabled: false
    // },
    {
      title: "起業コース",
      description: "個人ビジネスについて学び, 事業をスタートさせるところまでを目指します.",
      detail: "基本的に起業塾、開発塾、投資塾のいずれかを受講された方が対象となります。個人の目標達成や成長のために必要な課題をその時点のレベルに合わせて提供します。課題やアクションについては実行するたびに報告してもらい、面談時にフィードバックを行なっています。",
      price: "55,000円（月額）",
      ennabled: false
    },
    {
      title: "開発コース",
      description: "アプリ開発を学び, 公開してユーザーを獲得するところまでを目指します.",
      detail: "基本的に起業塾、開発塾、投資塾のいずれかを受講された方が対象となります。個人の目標達成や成長のために必要な課題をその時点のレベルに合わせて提供します。課題やアクションについては実行するたびに報告してもらい、面談時にフィードバックを行なっています。",
      price: "55,000円（月額）",
      ennabled: true
    },
    {
      title: "投資コース",
      description: "投資の全体像を学び, 実際に運用して損益を出すところまでを目指します.",
      detail: "基本的に起業塾、開発塾、投資塾のいずれかを受講された方が対象となります。個人の目標達成や成長のために必要な課題をその時点のレベルに合わせて提供します。課題やアクションについては実行するたびに報告してもらい、面談時にフィードバックを行なっています。",
      price: "55,000円（月額）",
      ennabled: false
    },
    // {
    //   title: "総合コース",
    //   description: "上記のすべてを盛り込んだカリキュラムを学び, 月８回通塾するコースです.",
    //   detail: "基本的に起業塾、開発塾、投資塾のいずれかを受講された方が対象となります。個人の目標達成や成長のために必要な課題をその時点のレベルに合わせて提供します。課題やアクションについては実行するたびに報告してもらい、面談時にフィードバックを行なっています。",
    //   price: "110,000円（月額）",
    //   ennabled: false
    // }
  ];

  const handleShowDetail = (service) => {
    setSelectedService(service);
    setModalOpen(true);
  };


  return (
    <section className="service">
    <PageHeader title="Service" description="提供サービス" />

    <div className="serviceContainer container">
      <div className="row">
        <motion.div
          className="personalImage col-12 col-lg-4"
          ref={ref}
          initial={{ x: "-10vw", opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : { x: "-10vw", opacity: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <img src="/projectImages/logo.png" alt="logo" />
        </motion.div>
        <motion.div
          className="personalInfo col-12 col-lg-8"
          ref={ref}
          initial={{ x: "10vw", opacity: 0 }}
          animate={inView ? { x: 0, opacity: 1 } : { x: "10vw", opacity: 0 }}
          transition={{ duration: 0.4, ease: "easeInOut" }}
        >
          <div className="contentContainer">
            <h5>経営・開発・投資の３軸で必要なコンサルティング・教育サービスをご提供</h5>
            <div className="productTableContainer">
              <table>
                <thead>
                  <tr>
                    <th>サービス</th>
                    <th className="description-column">概要</th>
                    <th>費用</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {services.map((service, index) => (
                    <tr key={index}>
                      <td>{service.title}</td>
                      <td className="description-column">{service.description}</td>
                      <td>{service.price}</td>
                      {/* その他のカラム... */}
                      <td>
                        <button class={service.ennabled?"":"button-disabled"} onClick={() => handleShowDetail(service)} disabled={!service.ennabled}>{service.ennabled?"詳細":"満枠"}</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <div className="buttonContainer">
              <a className="btn" href={resume} style={{textDecoration: "none", marginTop: 0}} target="_blank" rel="noreferrer">
                Qritarea教育サービス概要
              </a>
            </div> */}
            <div style={{fontSize:12, color:"silver", marginTop: 24, marginBottom: 24}}>
              <p>※ 起業塾, 投資塾は現在は募集を停止しています. 空き次第受講したい方はお問い合わせからご連絡ください.</p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
    <ServiceDetailModal
      isOpen={isModalOpen}
      onClose={() => setModalOpen(false)}
      serviceDetail={selectedService}
    />
    </section>
  );
};

export default Service;

